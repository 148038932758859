import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "range"
    }}>{`Range`}</h1>
    <p>{`Create `}<inlineCode parentName="p">{`<input type="range" />`}</inlineCode>{` with `}<inlineCode parentName="p">{`range`}</inlineCode>{` class to start to using it. You can add a `}<inlineCode parentName="p">{`min`}</inlineCode>{` and `}<inlineCode parentName="p">{`max`}</inlineCode>{` to work only with values between and `}<inlineCode parentName="p">{`step`}</inlineCode>{` attribute to specifies the granularity that the value must adhere to.`}</p>
    <h2 {...{
      "id": "basic-example"
    }}>{`Basic Example`}</h2>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label htmlFor='range' className='display-block'>
      Label
    </label>
    <input min='50' max='80' type="range" className='range mb-3' id='range' name='range' />
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label
      for='range'
      class='display-block'
    >
      Label
    </label>
    <input
      min='50'
      max='80'
      type="range"
      class='range mb-3'
      id='range'
      name='range'
    />
  </div>
`}</code></pre>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <p>{`An input field can show that it is disabled. Add `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` attribute/class on input.`}</p>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label htmlFor='range-disabled' className='display-block'>
      Label
    </label>
    <input type="range" className='range mb-3' id='range-disabled' name='range-disabled' disabled />
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label
      for='range-disabled'
      class='display-block'
    >
      Label
    </label>
    <input
      type="range"
      class='range mb-3'
      id='range-disabled'
      name='range-disabled'
      disabled
    />
  </div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      